<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Data PKS Masa Berlaku Habis Per 3 Bulan</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoading"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template v-slot:cell(aksi)="listData">
                  <div class="d-inline-block">
                    <router-link
                      class="btn btn-sm rounded btn-primary dark-btn-primary"
                      :to="{
                        name: 'kerjasama.pks.detail',
                        params: { idPks: listData.item.idPks }
                      }"
                      role="button"
                      >Lihat data<i class="ri-search-line ml-2"></i
                    ></router-link>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!-- <b-row class="p-2 mt-2">
                <b-col>
                  <b-btn class="btn-block" variant="danger"
                    >Unduh Excel XLS</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="secondary"
                    >Unduh Excel XLSX</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="success">Unduh CSV</b-btn>
                </b-col>
              </b-row> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
// import { Vue } from 'vue-property-decorator'
// import store from '@/store/index'
// import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'master.jenisPks',
  computed: {
    ...mapState('authService', ['token'])
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Get data jenis PKS
     */
    async getLaporanBerlakuPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getLaporanBerlakuPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          this.listData.map(
            a =>
              (a.tanggalBerakhir = moment(a.tanggalBerakhir).format(
                'DD-MM-YYYY'
              ))
          )
          /** Set the initial number of items */
          this.totalRows = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      await Promise.all([this.getLaporanBerlakuPks_()])
    }
  },
  data() {
    return {
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // Table
      thead: [
        {
          label: 'Kode PKS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Jenis KS',
          key: 'jenisKs',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Mitra',
          key: 'namaMitra',
          class: 'text-left w-75',
          sortable: true
        },
        {
          label: 'Tanggal Berakhir PKS',
          key: 'tanggalBerakhir',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      listData: []
    }
  },
  beforeRouteEnter(to, from, next) {
    return next()
    // const idUnitKs = store.state.authService.idUnitKs
    // if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
    //   return next()
    // } else {
    //   Vue.$toast.error('Anda tidak memiliki hak akses')
    //   return next({ name: 'sidebar.dashboard' })
    // }
  }
}
</script>
